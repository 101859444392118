<template >
    <div>
      <Loading v-if="loading"></Loading>
      <div v-if="!loading">
          <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a @click="routeTo('blog')">Blog</a></li>
          
        </ol>
        <h2>Blog</h2>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-8 entries">

            <SingleNews v-if="singleNews" :NewsData="singleNews"></SingleNews>
           

          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">

              <!-- End sidebar search formn-->
<!-- 
              <h3 class="sidebar-title">Categories</h3>
              <div class="sidebar-item categories">
                <ul>
                  <li><a href="#">General <span>(25)</span></a></li>
                  <li><a href="#">Lifestyle <span>(12)</span></a></li>
                  <li><a href="#">Travel <span>(5)</span></a></li>
                  <li><a href="#">Design <span>(22)</span></a></li>
                  <li><a href="#">Creative <span>(8)</span></a></li>
                  <li><a href="#">Educaion <span>(14)</span></a></li>
                </ul>
              </div> -->
              <!-- End sidebar categories-->

              <h3 class="sidebar-title">Recent Posts</h3>
              <div class="sidebar-item recent-posts">
               
                <div class="post-item clearfix" v-for="(news,index) in recentNews" :key="index">
                  <img  :src="news.bannerImages" alt="">
                  
                  <h4><a @click=" routeTo('blog_description',news)">{{news.header}}</a></h4>
                  <time datetime="2020-01-01">{{news.dateNews}}</time>
                </div>

                

              </div><!-- End sidebar recent posts-->

              <h3 class="sidebar-title">Tags</h3>
              <div class="sidebar-item tags">
                <ul>
                  <li v-for="(tag,index) in AllTags" :key="index"><a @click="routeTo('blog','',tag)">{{tag.name}}</a></li>

                </ul>
              </div>
              
              <!-- End sidebar tags-->

            </div><!-- End sidebar -->
          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->
    </div>
    </div>
    
</template>
<script>
import SingleNews from '../web-admin/news/SingleNews.vue'
import { getDatas, getFieldById, getFields, postDataHeader } from '../assets/js/service';
 import Loading from './container/Loading.vue'
export default { 

   metaInfo: {
    // Children can override the title.
    title: 'Blog and News',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Blog and News"},
      {property:"og:title" ,content:"First Consult Blog and News"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/blog"},


      {name:"twitter:title" ,content:"First Consult Blog and News"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/blog"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
  components:{
    SingleNews,
    Loading
  },
  data() {
    return {
      recentNews:null,
      AllTags:null,loading:false,
      singleNews:null
    }
  },
    mounted() {

this.$store.commit('set',['loading',true]);
    let token = localStorage.getItem('token');
   
    getFieldById('news',this.$route.params.id,token,'news-comments').then(resp=>{
      this.singleNews = resp.data; 
      this.$store.commit("set",["singlePageData",resp.data]);
      getDatas('news/',token,{type:'date',value:'DESC'},10).then(
      resp=>{
        this.recentNews = resp.data;
        getFields('tags',token).then(resp=>{
        this.AllTags = resp.data;
         this.$store.commit('set',['loading',false]);
      })
        
      }
    )
      
    })
    
  }, watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
     },

    },
  methods: {
    routeTo(route,news,tag){
      if(route == "blog_description"){
        this.$store.commit("set", ["singlePageData", news]);
        this.$router.replace('/'+route+'/'+news.id)
        this.$router.go();
      }else if(route == "blog"){
        let token = localStorage.getItem("token");
        postDataHeader('tags/tagRelatedDatas',{tagid:tag.id,type:'get news'},token).then(resp=>{
        this.$store.commit("set", ["TagNews", {news:resp.data.Data,tag:tag.name}]);
        this.$router.push('/'+route);
        })        .catch(e=>{
          Vue.$toast.error(
      "connection refused", {
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    );
        })
      }
      
      else{
        this.$router.push(route);
      }
    },
  },
};
</script>
<style lang="">
</style>