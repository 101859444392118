<template>
  <div>
    <div></div>
    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-12 entries">
            <article class="entry entry-single">
              <div class="entry-img">
                <img
                  :src="news.bannerImages"
                  alt=""
                  class="img-fluid"
                  width="100%"
                />
              </div>

              <h2 class="entry-title">
                <a href="blog-single.html">{{ news.header }}</a>
              </h2>

              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-globe"></i>
                    <a href="blog-single.html">First Consult</a>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="bi bi-clock"></i>
                    <a href="blog-single.html"
                      ><time datetime="2020-01-01">{{ news.dateNews }}</time></a
                    >
                  </li>
                  <!-- <li class="d-flex align-items-center"><i class="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li> -->
                </ul>
              </div>

              <div class="entry-content">
                <span v-html="news.details" style="font-size: 15px;"></span>

                <b-row v-if="news.newsImages.length">
                  <b-col
                    md="12"
                    v-for="(images, index) in news.newsImages"
                    :key="index"
                  >
                    <img
                      :src="images"
                      alt=""
                      width="100%"
                      style="padding-top: 20px"
                    />
                  </b-col>
                </b-row>
                <blockquote v-if="news.quotes">
                  <p>
                    {{ news.quotes }}
                  </p>
                </blockquote>

                <div v-for="(subNews, index) in news.subNews" :key="index">
                  <img
                    v-if="subNews.bannerImages"
                    :src="subNews.bannerImages"
                    class="img-fluid"
                    alt=""
                  />

                  <h3>{{ subNews.header }}</h3>
                  <nl2br tag="p" :text="subNews.details" style="font-size: 15px;" />
                  <blockquote v-if="subNews.quotes">
                    <p>
                      {{ subNews.quotes }}
                    </p>
                  </blockquote>
                </div>
              </div>

              <div class="entry-footer">
                <ul class="tags">
                  <li v-for="(tag, index) in Tags" :key="index">
                    <a @click="routeTo('blog', tag)">{{ tag.name }}</a>
                  </li>
                </ul>
              </div>
              <div class="blog-comments">
              <br>
              <br>
                <h4 class="comments-count">
                  {{ news["news-comments"].length }} Comments
                </h4>

                <div v-if='news["news-comments"].length' class="scrollable-element scrollable-content" ref="conversations">
                  <div
                    id="comment-1"
                    class="comment"
                    v-for="(comment, index) in news['news-comments']"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div>
                        <h5>
                          <a href="" class="disabled-link">{{
                            comment.full_name
                          }}</a>
                        </h5>
                        <time datetime="2020-01-01">{{
                          Date(comment.date).toString()
                        }}</time>
                        <p style="padding-bottom:0;margin-bottom: 0;">
                          {{ comment.message }}
                        </p>
                      </div>
                      <div>
                   
                      <b-button class="float-right" variant="danger" v-if="token && path.startsWith('/home/news/single_news/')"  @click="globalDelete('news-comments', comment.id)">Delete  <b-spinner
                style="width: 10px; height: 10px"
                v-if="loading"
              ></b-spinner></b-button>
                      </div>
                    </div>
                  </div>
                  <!-- End comment #1 -->

                  <!-- End comment #4 -->
                </div>
                <div class="reply-form">
                  <h4>Leave a Reply</h4>
                  <p>
                    Your email address will not be published. Required fields
                    are marked *
                  </p>

                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                        name="name"
                        type="text"
                        class="form-control"
                        v-model="newsComment.full_name"
                        placeholder="full Name*"
                      />
                      <p
                        class="error-message text-center"
                        v-if="newsCommentError.full_name"
                      >
                        Full Name can't be empty
                      </p>
                    </div>
                    <div class="col-md-6 form-group">
                      <input
                        name="email"
                        type="text"
                        class="form-control"
                        placeholder="Your Email*"
                        v-model="newsComment.email"
                      />
                      <p
                        class="error-message text-center"
                        v-if="newsCommentError.email"
                      >
                        Email can't be empty
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col form-group">
                      <textarea
                        name="comment"
                        class="form-control"
                        placeholder="Your Comment*"
                        v-model="newsComment.message"
                      ></textarea>
                      <p
                        class="error-message text-center"
                        v-if="newsCommentError.message"
                      >
                        Message can't be empty
                      </p>
                    </div>
                  </div>
                  <button
                    type="submit"
                    @click="postComment"
                    class="btn btn-primary"
                  ><b-spinner v-if="loading" small label="Small Spinner"></b-spinner>
                    Post Comment
                  </button>
                </div>
              </div>
              <!-- End blog comments -->
            </article>
            <!-- End blog entry -->
          </div>
          <!-- End blog entries list -->
        </div>
      </div>
    </section>
    <!-- End Blog Single Section -->
    <p style="font-size: 12px">
      The views expressed in the articles are not necessarily shared by First
      Consult or any of its clients or partners.
    </p>
  </div>
</template>
<script>
import ImagPicker from "../Common/ImageUpload.vue";
import Vue from "vue";
import Nl2br from "vue-nl2br";
const {
  imageToBanner,
  pathField,
  postDataHeader,
  postFields,
  getFields,
  getFieldById,
} = require("../../assets/js/service");
export default {
  components: {
    ImagPicker,
    Nl2br,
  },
  watch: {
    "$store.state.singlePageData": {
      //<----------your state call in string
      handler() {
        console.log('tttttttttttttttttttttttttttssssssdaaaaa');
        this.loading =true
        this.news = this.$store.state.singlePageData;
        console.log(this.news);
      },
      deep: true,
    },
      "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  
      "$store.state.viewReload": {
      handler() {
        if (this.$store.state.viewReload) {
          this.onCreateLoading();
          this.$store.commit("set", ["viewReload", false]);
        }
      },
    },

  },
  data() {
    return {
      news: this.$store.state.singlePageData,
      loading:false,
      newsComment: {
        full_name: null,
        email: null,
        message: null,
        newsId: null,
      },
      newsCommentError: {
        full_name: false,
        email: false,
        message: false,
      },
      edit: false,
      sliding: null,
      slide: 0,
      header: null,
      path:this.$route.path,
      details: null,
      token: localStorage.getItem('token'),
      Tags: [],
    };
  },
  mounted() {
    this.$store.commit("set", ["images", []]);
    this.$store.commit("set", ["images1", []]);
    
    let token = localStorage.getItem("token");
    if (!this.$store.state.singlePageData) {
      this.$router.push("/news");
    } else {
      console.log("sssssssssssssd");
      postDataHeader(
        "tags/tagRelatedDatas",
        { newsid: this.$store.state.singlePageData.id, type: "get tags" },
        token
      ).then((resp) => {
        this.Tags = resp.data.Data;
      });
    }
  },
  methods: {
    onCreateLoading(){
      this.loading = true;
         let token = localStorage.getItem("token");
          getFieldById('news',this.$route.params.id,token,'news-comments').then(resp=>{
            this.news = resp.data;
            this.loading =false;
          })
    },
    postComment() {
      this.newsComment.newsId = this.news.id;
      let token = localStorage.getItem("token");
      if (
        !this.newsComment.email ||
        !this.newsComment.full_name ||
        !this.newsComment.message
      ) {
        if (!this.newsComment.email) {
          this.newsCommentError.email = true;
        }
        if (!this.newsComment.message) {
          this.newsCommentError.message = true;
        }
        if (!this.newsComment.full_name) {
          this.newsCommentError.full_name = true;
        }
      } else {
        this.loading = true;
        postFields("news-comments", this.newsComment, token).then((resp) => {
          getFieldById("news", this.news.id, token, "news-comments").then(
            (resp) => {
              this.newsComment = {
                full_name: null,
                email: null,
                message: null,
                web_site: null,
                newsId: null,
              };
              this.news = resp.data;
              this.loading =false;
              const container = this.$refs.conversations;
              container.scrollTop = container.scrollHeight + 50;
              Vue.$toast.success("successfully posted message", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            }
          );
        });
      }
    },
    routeTo(route, tag) {
      if (route == "blog") {
        let token = localStorage.getItem("token");
        postDataHeader(
          "tags/tagRelatedDatas",
          { tagid: tag.id, type: "get news" },
          token
        ).then((resp) => {
          this.$store.commit("set", [
            "TagNews",
            { news: resp.data.Data, tag: tag.name },
          ]);
          this.$router.push(route);
        });
      } else {
        this.$router.push(route);
      }
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    makeBanorimage(img) {
      return imageToBanner("banner", img);
    },
    editNews() {
      this.edit = !edit;
    },
    UpdateNews() {
      let updatedNews = {};
      let update = false;
      if (this.header) {
        updatedNews.header = this.header;
        update = true;
      }
      if (this.details) {
        updatedNews.details = this.details;
        update = true;
      }
      if (this.$store.state.images.length > 0) {
        updatedNews.bannerImages = this.$store.state.images;
        update = true;
      }
      if (this.$store.state.images1.length > 0) {
        updatedNews.newsImages = this.$store.state.images1;
        update = true;
      }

      if (update) {
        let token = localStorage.getItem("token");
        pathField("news", this.news.id, updatedNews, token).then((resp) => {
          this.news = resp.data;
          this.$store.commit("set", ["singlePageData", this.news]);
          (this.header = null),
            (this.details = null),
            this.$store.commit("set", ["images", []]);
          this.$store.commit("set", ["images1", []]);
          Vue.$toast.success("succcess full", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
      } else {
  
      }
    },
  },
};
</script>
<style>
</style>